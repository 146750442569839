<template>
    <div class="docs-holder">

        <div v-if="integrations">
            <el-row v-for="(array, index) of integrationsInGrid" :gutter="20" v-bind:key="index">
                <el-col style="margin-top: 2em;" v-for="(integration, index) of array" :span="6" v-bind:key="index">
                    <Card @click="getintegration(integration)" class="integration-card">
                        <template #header>
                            <img :src="integration.image" style="height: 50px;" class="image" />
                            <h3 class="mt-4"> {{ integration.name }}</h3>
                        </template>
                        <template #subtitle>
                            <span class="mt-2">{{ integration.name }}</span>
                        </template>
                        <template #content>
                        <div class="pt-2" style="border-top: #1D1E23 solid 2px;">
                            <Button style="color: white;" class="" @click="openDrawer(integration.name )" outlined>Integrate</Button>
                        </div>

                        </template>
                    </Card>
                </el-col>
            </el-row>
        </div>
    </div>
    <intergration-drawer v-if="intergrationDrawer" :open="intergrationDrawer" :title="drawerTitle" @reset="intergrationDrawer = false" />
</template>
  
<script>

// for every foruth element
import IntergrationDrawer from "./IntegrationDrawer.vue"

var array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

var cutItemsBy4 = function (bigarray) {
    var size = 4; var arrayOfArrays = [];
    for (var i = 0; i < bigarray.length; i += size) {
        arrayOfArrays.push(bigarray.slice(i, i + size));
    }
    
    return arrayOfArrays
}


export default {
    data: () => ({
        integrations: [{ name: "github", image: require("@/assets/github.png"),}, { name: "confluence", image: require('@/assets/confluence.svg')}, { name: "google drive", image: require('@/assets/googledrive.svg')}, { name: "notion", image: require('@/assets/notion.svg')}, {name: "proxy url", image: "https://cdn.glitch.global/40d5f2e1-d6f1-4b4e-b603-44a0049b2e40/DALL%C2%B7E%202023-03-10%2016.11.49.png"}],
        integrationsInGrid: null,
        friendlyNames: null,
        codebases: null,
        showWelcome: false,
        drawerTitle: "",
        intergrationDrawer: false,
        org: null,
        url: null
    }),
    components: {
        IntergrationDrawer
    },
    async mounted() {
        //var authStatus = await this.$authInstance.authnActionIfNeeded()
        var idtoken = await this.$authInstance.getToken()
        this.url = await this.$authInstance.getBaseUrl()
        this.org = await this.$authInstance.getOrg()
        
        this.integrationsInGrid = cutItemsBy4(this.integrations)
        //await this.getDocs()
        // this.showWelcome = this.integrations == null
    },
    methods: {
        openDrawer(title) {
            
            this.drawerTitle = title
            this.intergrationDrawer = !this.intergrationDrawer
        },
        getFileName(file) {
            var fileFragments = file.split("/")
            return fileFragments[fileFragments.length - 1]
        },
        getintegration(integration) {
            
            this.$emit("set", integration.content)
        },
        getRepoName(repo) {
            var repoFragments = repo.split("/")
            return repoFragments[repoFragments.length - 1].split(".git")[0]
        },
        generateIdLink(repo) {
            var encodedStringBtoA = btoa(repo);
            return `/docs/${encodedStringBtoA}`
        },
        async getDocs() {
            // try {
            //   var myHeaders = new Headers();
            //   var idtoken = await this.$authInstance.getToken()
            //   myHeaders.append("Authorization", `Bearer ${idtoken}`);

            //   var requestOptions = {
            //     method: 'GET',
            //     headers: myHeaders,
            //     redirect: 'follow'
            //   };

            //   var codebaseRes = await fetch(`${this.url}/docs?searchKey=doctype&searchValue=misc`, requestOptions)
            //   var codebasesJson = await codebaseRes.json()
            //   
            //   this.integrations = cutItemsBy4(codebasesJson.data)

            // } catch (e) {
            //   
            // }
        }
    }
}
</script>
  
<style>


.integration-card .p-card-body {
    padding-left: 0px;
    padding-right: 0px;
}

.integration-card {
    padding-left: 1.5rem !important;
    padding-top: 1.25rem !important
}

</style>