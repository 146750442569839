<template>
    <div>
        <Dialog v-if="drawer && title === 'proxy url'" v-model:visible="drawer" :header="title" :modal="true">
            <div class="p-fluid">
                <div class="p-field">
                    <label for="name">Name</label>
                    <InputText id="name" v-model="proxyUrlForm.name" />
                </div>
                <div class="p-field">
                    <label for="url">URL</label>
                    <InputText id="url" v-model="proxyUrlForm.url" />
                </div>
                <div class="p-field">
                    <Button label="Submit" @click="submitFormData()" />
                </div>
            </div>
        </Dialog>
        <Dialog v-else-if="drawer && title === 'google drive'" v-model:visible="drawer" :header="title" :modal="true">
            <div class="p-fluid">
                <h3>Google Drive Status: {{ currentIntegrationActive }}</h3>
                <Button v-if="currentIntegrationActive" severity="danger" class="mt-4" @click="deleteSyncIntegration(title)" label="Delete Integration" />
                <Button v-else @click="getGoogleRedirectUrl()" class="mt-4" label="Integrate Google Drive" />
            </div>
        </Dialog>
        <Dialog v-else-if="drawer && title === 'notion'" v-model:visible="drawer" :header="title" :modal="true">
            <div class="p-fluid">
                <h3>Notion Status: {{ currentIntegrationActive }}</h3>
                <Button v-if="currentIntegrationActive" severity="danger" class="mt-4" @click="deleteSyncIntegration(title)" label="Delete Integration" />
                <Button v-else @click="getNotionRedirectUrl()" class="mt-4" label="Integrate Notion" />
            </div>
        </Dialog>
        <Dialog v-else-if="drawer && title === 'github'" v-model:visible="drawer" :header="title" :modal="true">
            <div class="p-fluid">
                <h3>Github App Setup: {{ currentIntegrationActive }}</h3>
                <Button v-if="currentIntegrationActive" severity="danger" class="mt-4" @click="deleteSyncIntegration(title)" label="Delete Integration" />
                <Button v-else @click="getGoogleRedirectUrl()" class="mt-4" label="Integrate Google Drive" />
            </div>
        </Dialog>
        <Dialog v-else v-model:visible="drawer" :header="title" :modal="true">
            <TabView v-model="activeName" class="demo-tabs" @tab-change="handleClick">
                <TabPanel header="Setup">
                    <div class="p-fluid">
                        
     
                        <div v-for="(item, index) in formItems" :key="index" class="p-field">
                            <label :for="item.key">{{ item.title }}</label>
                            <InputText :id="item.key" :type="item.type" v-model="form_data[item.key]" />
                        </div>
                        <div class="p-field">
                            <Button label="Submit" @click="submitFormData()" />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Codebases">
                    <codebase-cards v-if="title" :integration="title" />
                </TabPanel>
            </TabView>
        </Dialog>
    </div>
</template>

<script>

import { GalleryThumbnailsIcon } from 'lucide-vue-next';
import CodebaseCards from './CodebaseIntegrationCards.vue';

export default {
    props: {
        open: Boolean,
        title: String,
        reference: String,
    },
    components: {
        CodebaseCards,
    },
    methods: {
        async getProxyUrl() {
            var myHeaders = new Headers();
                   try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
               // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/proxy_url`, requestOptions)
                var result = await saveResponseTwo.json()
                
                var {name, id, url} = result[0]
                this.proxyUrlForm.name = name
                this.proxyUrlForm.url = url
                // 
                // var response = await saveResponseTwo.json()
                // 
                // var integration = response?.docs[0] 
                // 
                // this.form_data = {url: integration?.url, user: integration?.user, api_token: integration?.api_token || "set"}
            } catch (e) {
                
            }

        },
        async checkGoogleIntegration() {
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/google_drive_integrations`, requestOptions)
                var response = await saveResponseTwo.json()
                console.log(response?.docs?.length > 0)
                if(response?.docs?.length > 0) return true
                else return false
            } catch (e) {
                console.log(e)
            }
        },
        async getSyncIntegration(integration) {
            let integrations = {"confluence": "confluence", "google drive": "google_drive_integrations", "github": "github_app_integrations", "notion": "notion_integrations"}
            let syncSource = integrations[integration]
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/sync_integration?sync_source=${syncSource}`, requestOptions)
                var response = await saveResponseTwo.json()
                console.log(response?.data?.length > 0)
                if(response?.data?.length > 0) return true
                else return false
            } catch (e) {
                console.log(e)
            }
        },

        async deleteSyncIntegration(integration) {
            let integrations = {"confluence": "confluence", "google drive": "google_drive_integrations", "github": "github_app_integrations", "notion": "notion_integrations"}
            let syncSource = integrations[integration]
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var requestOptions = {
                    method: 'DELETE',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/sync_integration?sync_source=${syncSource}`, requestOptions)
                this.currentIntegrationActive = false
                
            } catch (e) {
                console.log(e)
            }
        },
        
        async getIntegrations() {
            if(this.title == "proxy url") return await this.getProxyUrl()
            var myHeaders = new Headers();
                   try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
               // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/intergrations?searchKey=name&searchValue=${this.title}&decrypt=yes`, requestOptions)
                
                var response = await saveResponseTwo.json()
                
                var integration = response?.docs[0] 
                
                this.form_data = {url: integration?.url, user: integration?.user, api_token: integration?.api_token || "set"}
            } catch (e) {
                
            }

        },
        handleClose() {
            this.$emit("reset")
        },
        async getGoogleRedirectUrl() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/google/auth`, requestOptions)
                let response = await saveResponseTwo.json()
                if(response?.url) {
                    window.location.href = response.url
                }
            } catch (e) {
                
            }

        },
        async getNotionRedirectUrl() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/notion/auth`, requestOptions)
                let response = await saveResponseTwo.json()
                if(response?.url) {
                    window.location.href = response.url
                }
            } catch (e) {
                
            }

        },
        async createProxyUrl() {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                var raw = JSON.stringify({...this.proxyUrlForm})
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/proxy_url`, requestOptions)
            } catch (e) {
                
            }

        },
        async submitFormData() {
            if(this.title == "proxy url") return await this.createProxyUrl()
            
            
            var postBodyObject = {...this.form_data}
            // for(const formItem of this.formItems) {
            //     postBodyObject[formItem.key] = formItem.value
            // }


       
            var myHeaders = new Headers();
                   try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var raw = JSON.stringify({name: this.title, ...postBodyObject});
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
               // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/intergrations`, requestOptions)
            } catch (e) {
                
            }
        },
    },
    data: () => ({
        drawer: null,
        formItems: null,
        form_data: { user: 'boogie' },
        googleDriveSetup: null,
        currentIntegrationActive: null,
        baseUrl: '',
        proxyUrlForm: {
            url: '',
            name: '',
        },
        form_schemas: {
            confluence: [
                { title: 'Confluence Username', details: '', type: 'text', key: 'user', value: '' },
                { title: 'Confluence API Token', details: '', type: 'password', key: 'api_token', value: '' },
                { title: 'Confluence Base Url', details: '', type: 'text', key: 'url', value: '' },
            ],
            'proxy url': [
                { title: 'Proxy Server Name', details: '', type: 'text', key: 'name', value: '' },
                { title: 'Proxy Server Url', details: '', type: 'url', key: 'url', value: '' },
            ],
        },
    }),
    async mounted() {
        this.drawer = this.open
        this.formItems = this.form_schemas[this.title]
        await this.getIntegrations()
        this.baseUrl = await this.$authInstance.getBaseUrl()
        this.googleDriveSetup = await this.checkGoogleIntegration()
        this.currentIntegrationActive = await this.getSyncIntegration(this.title)
        
        // ...existing mounted logic...
    },
    unmounted() {
        // ...existing unmounted logic...
    },
};
</script>

<style>
.p-field {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

</style>